@import url(https://fonts.googleapis.com/css?family=Satisfy);
body {
  margin: 0;
  font-family: "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e2e2e2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.satisfy {
  font-family: "Satisfy", cursive;
}

.header {
  font-size: 35px;
}

.primarySize {
  font-size: 30px;
}

.primaryColor {
  color: #333;
}

.secondarySize {
  font-size: 20px;
}

.secondaryColor {
  color: #e2e2e2;
}

.opaqueFont {
  color: rgba(0, 0, 0, 0.4);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.map-container {
  margin: auto;
  width: 50%;
  height: 50%;
  padding: 175px;
  }

.invalid {
  border-color: red;
}

.button {
  background-color: rgba(52, 82, 104, 1);
}

.serviceCard {
  width: 300px;
  height: 300px;
  /* filter: blur(5px); */
  position: relative;
  overflow: hidden;
}

.serviceTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 800ms;
}

.serviceCard:hover .serviceTitle {
  transform: translate(-50px, -115px);
}
.serviceCard:hover .serviceDescription {
  opacity: 1;
  transition: opacity 1s;
}
.serviceCard:hover .cardFilter {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 1s;
}

.serviceDescription {
  position: absolute;
  top: 75px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  opacity: 0;
}

.cardFilter {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.logoFont {
  font-family: "Satisfy", cursive;
  font-size: 50px;
}

.car__body{animation:shake 0.2s ease-in-out infinite alternate}.car__line{transform-origin:center right;stroke-dasharray:22;animation:line 0.8s ease-in-out infinite;animation-fill-mode:both}.car__line--top{animation-delay:0s}.car__line--middle{animation-delay:0.2s}.car__line--bottom{animation-delay:0.4s}@keyframes shake{0%{transform:translateY(-1%)}100%{transform:translateY(3%)}}@keyframes line{0%{stroke-dashoffset:22}25%{stroke-dashoffset:22}50%{stroke-dashoffset:0}51%{stroke-dashoffset:0}80%{stroke-dashoffset:-22}100%{stroke-dashoffset:-22}}

.logoFont {
  font-family: "Satisfy", cursive;
  font-size: 50px;
}

.navBar {
  height: 100px;
  width: 100%;
  background: linear-gradient(rgb(52, 82, 104), rgba(52, 82, 104, 0));
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  z-index: 1000;
}

.logoFont {
  font-family: "Satisfy", cursive;
  font-size: 50px;
}

.logoFont {
  font-family: "Satisfy", cursive;
  font-size: 50px;
}

.logoFont {
  font-family: "Satisfy", cursive;
  font-size: 50px;
}

.w-400px {
  width: 400px;
}

.w-550px {
  width: 550px;
}

.loader{width:100vw;height:100vh;zoom:100%;top:0;left:0;position:fixed;z-index:2;background-color:#e2e2e2}.car__body{animation:shake 0.2s ease-in-out infinite alternate}.car__line{transform-origin:center right;stroke-dasharray:22;animation:line 0.8s ease-in-out infinite;animation-fill-mode:both}.car__line--top{animation-delay:0s}.car__line--middle{animation-delay:0.2s}.car__line--bottom{animation-delay:0.4s}@keyframes shake{0%{transform:translateY(-1%)}100%{transform:translateY(3%)}}@keyframes line{0%{stroke-dashoffset:22}25%{stroke-dashoffset:22}50%{stroke-dashoffset:0}51%{stroke-dashoffset:0}80%{stroke-dashoffset:-22}100%{stroke-dashoffset:-22}}

.fleet-page {
  text-align: center;
  /* background-color: #abbba3; */
}

.fleet-list {
  width: 40%;
  margin: auto;
  border: 0px solid black;
  color: #f8f9fe
}
.fleet-item {
  padding: 20px;
  border: 1px solid;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 10px;
  background-color: rgba(52, 82, 104, 1);
  color: #f8f9fe
}

.vehicle-list td, 
.vehicle-list th,
table.vehicle-list { 
   border: 1px solid rgba(59, 57, 57, 0.527); 
   background-color: rgba(52, 82, 104, 1); 
   color: #f8f9fe
} 

table {
  width: 100%;
  text-align: center;
}

.overlay {
  background-color: rgba(223, 202, 202, 0.788);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
}

.dialog-view {
  background-color: rgba(43, 48, 39, 0.719);
  /* min-height: 50%; */
  width: 70%;
  margin: auto;
}

.dialog-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.close-button {
  font-weight: bold;
  border: 1px solid rgb(163, 28, 28);
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.dialog-content {
  margin-bottom: 20px;
}

