@import url("https://fonts.googleapis.com/css?family=Satisfy");

body {
  margin: 0;
  font-family: "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e2e2e2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.satisfy {
  font-family: "Satisfy", cursive;
}

.header {
  font-size: 35px;
}

.primarySize {
  font-size: 30px;
}

.primaryColor {
  color: #333;
}

.secondarySize {
  font-size: 20px;
}

.secondaryColor {
  color: #e2e2e2;
}

.opaqueFont {
  color: rgba(0, 0, 0, 0.4);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
