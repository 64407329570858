.fleet-page {
  text-align: center;
  /* background-color: #abbba3; */
}

.fleet-list {
  width: 40%;
  margin: auto;
  border: 0px solid black;
  color: #f8f9fe
}
.fleet-item {
  padding: 20px;
  border: 1px solid;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 10px;
  background-color: rgba(52, 82, 104, 1);
  color: #f8f9fe
}

.vehicle-list td, 
.vehicle-list th,
table.vehicle-list { 
   border: 1px solid rgba(59, 57, 57, 0.527); 
   background-color: rgba(52, 82, 104, 1); 
   color: #f8f9fe
} 

table {
  width: 100%;
  text-align: center;
}

.overlay {
  background-color: rgba(223, 202, 202, 0.788);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
}

.dialog-view {
  background-color: rgba(43, 48, 39, 0.719);
  /* min-height: 50%; */
  width: 70%;
  margin: auto;
}

.dialog-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.close-button {
  font-weight: bold;
  border: 1px solid rgb(163, 28, 28);
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.dialog-content {
  margin-bottom: 20px;
}
